/* ====== PRELOADER ====== */
$("#preloader")
        .delay(2000)
        .fadeOut(600);
setTimeout(function () {
    $(".body")
            .removeClass("preloader_class_old")
            .addClass("preloader_class_new");
}, 2000);
/* ====== TO TOP ====== */
jQuery(document).ready(function ($) {
    var offset = 100,
            offset_opacity = 1200,
            scroll_top_duration = 700,
            $back_to_top = $(".cd-top");
    $(window).scroll(function () {
        $(this).scrollTop() > offset
                ? $back_to_top.addClass("cd-is-visible")
                : $back_to_top.removeClass("cd-is-visible cd-fade-out");
        if ($(this).scrollTop() > offset_opacity) {
            $back_to_top.addClass("cd-fade-out");
        }
    });
    $back_to_top.on("click", function (event) {
        event.preventDefault();
        $("body,html").animate(
                {
                    scrollTop: 0
                },
                scroll_top_duration
                );
    });
});
/* ====== FLEX SLIDER ====== */
$(".flexslider").flexslider({
    slideshow: true,
    animation: "fade",
    slideshowSpeed: 6000,
    animationLoop: true,
    pauseOnHover: false,
    controlNav: true,
    directionNav: false,
    keyboard: false
});
/* ====== COUNTERR ====== */
$(".counting").each(function () {
    var $this = $(this),
            countTo = $this.attr("data-count");
    $({countNum: $this.text()}).animate(
            {
                countNum: countTo
            },
            {
                duration: 4500,
                easing: "linear",
                step: function () {
                    $this.text(Math.floor(this.countNum));
                },
                complete: function () {
                    $this.text(this.countNum);
                }
            }
    );
});
/* ====== MODAL ====== */
$("#myModal").on("shown.bs.modal", function () {
    $("#myInput").trigger("focus");
});
/* ====== NAVIGATION HOVER ====== */
const $dropdown = $(".dropdown");
const $dropdownToggle = $(".dropdown-toggle");
const $dropdownMenu = $(".dropdown-menu");
const showClass = "show";
$(window).on("load resize", function () {
    if (this.matchMedia("(min-width: 768px)").matches) {
        $dropdown.hover(
                function () {
                    const $this = $(this);
                    $this.addClass(showClass);
                    $this.find($dropdownToggle).attr("aria-expanded", "true");
                    $this.find($dropdownMenu).addClass(showClass);
                },
                function () {
                    const $this = $(this);
                    $this.removeClass(showClass);
                    $this.find($dropdownToggle).attr("aria-expanded", "false");
                    $this.find($dropdownMenu).removeClass(showClass);
                }
        );
    } else {
        $dropdown.off("mouseenter mouseleave");
    }
});
/* ====== CAREERS FILTER ====== */
$(window).load(function () {
    var $container = $(".portfolioContainer");
    $container.isotope({
        filter: "*",
        animationOptions: {
            duration: 750,
            easing: "linear",
            queue: false
        }
    });
    $(".portfolioFilter a").click(function () {
        var selector = '.isotope-item'

        if ($(".portfolioFilter .current").length > 0) {
            $(".portfolioFilter .current").removeClass("current");
        } else {
            $(this).addClass("current");
            var selector = $(this).attr("data-filter");
        }

        $container.isotope({
            filter: selector,
            layoutMode: 'fitRows',
            animationOptions: {
                duration: 750,
                easing: "linear",
                queue: false
            }
        });
        return false;
    });
});
/* ====== CUSTOM SELECT ====== */
$("select").each(function () {
    var $this = $(this),
            numberOfOptions = $(this).children("option").length;
    $this.addClass("select-hidden");
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');
    var $styledSelect = $this.next("div.select-styled");
    $styledSelect.text(
            $this
            .children("option")
            .eq(0)
            .text()
            );
    var $list = $("<ul />", {
        class: "select-options"
    }).insertAfter($styledSelect);
    for (var i = 0; i < numberOfOptions; i++) {
        $("<li />", {
            text: $this
                    .children("option")
                    .eq(i)
                    .text(),
            rel: $this
                    .children("option")
                    .eq(i)
                    .val()
        }).appendTo($list);
    }

    var $listItems = $list.children("li");
    $styledSelect.click(function (e) {
        e.stopPropagation();
        $("div.select-styled.active")
                .not(this)
                .each(function () {
                    $(this)
                            .removeClass("active")
                            .next("ul.select-options")
                            .hide();
                });
        $(this)
                .toggleClass("active")
                .next("ul.select-options")
                .toggle();
    });
    $listItems.click(function (e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass("active");
        $this.val($(this).attr("rel"));
        $list.hide();
    });
    $(document).click(function () {
        $styledSelect.removeClass("active");
        $list.hide();
    });



});


$('#careers_form').on('submit', function (e) {
    
    if ($(this).find('#country').val() > 0) {
        $('#err-msg').html('')
    } else {

        $('#err-msg').html('Please, choose country.')
        e.preventDefault();
        return;
    }

    if ($('#attach').get(0).files.length === 0) {
        $('#err-msg').html('Please, upload CV.')
        e.preventDefault();
    } else {
        $('#err-msg').html('')
    }
})



var PageImagesAdjuster = function () {
    return {
        init: function () {
            if (window.innerWidth >= 768) {
                this.adjust();
            } else {
                this.adjustRSP()
            }
        },
        adjust: function () {

            $('img[style*="float:right"]').each(function () {
                $(this).css({'margin-right': '-200px', 'margin-left': '15px', 'border': '10px solid #d00006'})
                $(this).parent().css({'display': 'inline'})
            })
            $('img[style*="float:left"]').each(function () {
                $(this).css({'margin-left': '-200px', 'margin-right': '15px', 'border': '10px solid #d00006'})
                $(this).parent().css({'display': 'inline'})
            })
        },
        adjustRSP: function () {

            $('img[style*="float:right"]').each(function () {
                $(this).css({'width': '100%'})
                $(this).parent().css({'display': 'inline'})
            })
            $('img[style*="float:left"]').each(function () {
                $(this).css({'width': '100%'})
                $(this).parent().css({'display': 'inline'})
            })
        }
    }

}();
if (window.innerWidth < 768 && $('#hp_news').length > 0) {
    $('#hp_news').find('#nav-tab').attr('role', null)

    $('#hp_news').find('.nav-link.active').removeClass('active')
    $('#hp_news').find('.nav-link').attr('data-toggle', null)
    $('#hp_news').find('.nav-link').attr('role', null)
    $('#hp_news').find('.nav-link').attr('aria-controls', null)
    $('#hp_news').find('.nav-link').attr('aria-selected', null)


    $('#hp_news').find('.nav-link').each(function () {
        $(this).attr('href', $(this).data('althref'))
    })
}



$('.page-gallery-carousel').slick({
    infinite: true,
    autoplay: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 7000
});